<template>
    <div class="main">
        <Navigation/>
    </div>
</template>
<script>
import Navigation from '../components/Navigation.vue'
export default {
    name:"MSR",
    components:{
        Navigation
    }
}
</script>
<style scoped>

</style>